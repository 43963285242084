
@import './assets/fonts/fontStyles.css';

:root {
    --primaryFont: #38568F;
    --dark1: #000000;
    --dark2: #121212;
    --darkSecond: #2D2D2D;
    --dark3: #333333;
    --dark4: rgba(63, 67, 74, 0.8);
    --dark5: #6E6E6F;
    --dark6: #93979C;
    --white: #ffffff;
    --secondLight: rgba(56, 161, 247, 0.2);
    --thirdLight: #DADADA;
    --hr: rgba(0, 0, 0, 0.1);

    --slideBg: linear-gradient(217.41deg, #38A1F7 6.15%, #02298A 80.33%, #021B79 93.64%);
    --proteanBg:linear-gradient(217.41deg, #6cb2ec 6.15%, #002b93 80.33%, #1b295c 93.64%);
    --buttonBg: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), linear-gradient(269.82deg, #38A1F7 0.1%, #38568F 99.85%);
    --bgGradientTB: linear-gradient(178.21deg, #021C7A 5.38%, #38A1F7 114.11%);
    --bgGradientLR: linear-gradient(89.89deg, #04207E 6.18%, #2D85DD 86.57%);
    --fg1: linear-gradient(96.72deg, #38568F 3.35%, #38A1F7 97.13%);
    --fg2: linear-gradient(87.01deg, #38568F 10.28%, #38A1F7 105.7%);
    --fg3: linear-gradient(180deg, #38568F 0%, #38A1F7 100%);
    --popupBg: rgba(0, 0, 0, 0.8);
    --menuHover: #ffffff33;
    --lightBackground: #F9F9F9;


    /* --border: 1px solid #ADB3BC; */
    --border: 1px solid #adb3bc80;
    --borderLight: 1px solid #E9E5E5;
    --cardBorder: 1px solid #F6F8F9;
    --border-color: rgba(0, 0, 0, 0.05);
    --border-color2: #ADB3BC;
    --border-color3: #C4C4C4;
    --borderGradient: linear-gradient(white, white), radial-gradient(circle at top left, #38568F,#38A1F7);

    --selectShadow: 0px 15px 12px rgba(0, 0, 0, 0.15);
    --cardShadow: 0px 4px 12px rgb(0 0 0 / 12%);
}

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: 'Proxima Nova', sans-serif;
}

::placeholder { 
    color: var(--dark6);
  }
  
  :-ms-input-placeholder { 
    color: var(--dark6);
  }
  
  ::-ms-input-placeholder { 
    color: var(--dark6);
  }
ul {
    list-style: none;
}
button {
    cursor: pointer;
}

button.themeBtn {
    background: var(--buttonBg);
    text-align: center;
    padding: 14px;
    color: var(--white);
    font-weight: 600;
    border-radius: 5px;
    border: none;
}

button.cancel {
    background: linear-gradient(89.59deg, #38568F 0.3%, #3872B5 36.94%, #38A1F7 99.6%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    position: relative;
}
button.cancel:before {
      content: '';
      height: 100%;
      width: 100%;
      position: absolute;
      left: 0;
      top: 0;
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), linear-gradient(269.82deg, #38A1F7 0.1%, #38568F 99.85%);
      opacity: 0.1;
      border-radius: 5px;
    }

.fd1 {
    color: var(--dark3);
    font-size: 40px;
    font-weight: 700;
}
.fd2 {
    color: var(--dark3);
    font-size: 24px;
    font-weight: 400;
}
.fd3 {
    color: var(--dark5);
    font-size: 24px;
    font-weight: 400;
}
.fd4 {
    color: var(--dark2);
    font-size: 22px;
    font-weight: 700;
}
.fd5 {
    color: var(--dark2);
    font-size: 20px;
    font-weight: 700;
}
.fd6 {
    color: var(--dark1);
    font-size: 20px;
    font-weight: 600;
}
.fd7 {
    color: var(--dark5);
    font-size: 18px;
    line-height: 32px;
}
.fd8 {
    color: var(--dark2);
    font-size: 16px;
    font-weight: 700;
}
.fd9 {
    color: var(--dark4);
    font-size: 16px;
    font-weight: 700;
}
.fp1 {
    color: var(--primaryFont);
    font-size: 20px;
    font-weight: 600;
}
.fg1 {
    font-size: 18px;
    font-weight: 600;
    background: var(--fg1);
}
.fg2 {
    font-size: 16px;
    font-weight: 700;
    background: var(--fg2);
}
.fg1, .fg2 {
    text-fill-color: transparent;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    -webkit-background-clip: text;
}

.fl1 {
    color: var(--white);
    font-size: 24px;
    font-weight: 600;
}
.fl2 {
    color: var(--white);
    font-size: 22px;
    font-weight: 700;
}
.fl3 {
    color: var(--white);
    font-size: 16px;
    font-weight: 600;
}

.rdt_TableHeadRow {
    border: 1px solid var(--thirdLight);
    border-radius: 5px;
}
    .rdt_TableCol {
      color: var(--dark5);
      font-size: 14px;
    }
  
  .rdt_TableCell {
    font-size: 14px;
  }



/* keyframes */

