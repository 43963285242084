@font-face {
    font-family: 'Proxima Nova';
    font-weight: 800;
    src: url('../fonts/PROXIMANOVA-BLACK-WEBFONT.TTF');
}
@font-face {
    font-family: 'Proxima Nova';
    font-weight: 700;
    src: url('../fonts/PROXIMANOVA-BOLD-WEBFONT.TTF');
}
@font-face {
    font-family: 'Proxima Nova';
    font-weight: 600;
    src: url('../fonts/PROXIMANOVA-SBOLD-WEBFONT.TTF');
}
@font-face {
    font-family: 'Proxima Nova';
    font-weight: 400;
    src: url('../fonts/PROXIMANOVA-REG-WEBFONT.TTF');
}
@font-face {
    font-family: 'Proxima Nova';
    font-weight: 300;
    src: url('../fonts/PROXIMANOVA-LIGHT-WEBFONT.TTF');
}
@font-face {
    font-family: 'Proxima Nova';
    font-weight: 100;
    src: url('../fonts/PROXIMANOVA-THIN-WEBFONT.TTF');
}